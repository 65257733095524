<template>
	<div class="login-page">
        <basic-page>
            <template slot="left">
                <basic-page-left></basic-page-left>
            </template>
            <template slot="right">
                <div class="login-content" v-if="mode === 'password'">
                    <div class="top-placeholder"></div>
					<div class="subtitle">{{$t('system.login_page_form_title')}}</div>
					<div class="form-content">
                        <!-- <div class="login-type">
                            <div class="type-item">手机号</div>
                            <div class="type-item">邮箱/用户名</div>
                        </div> -->
                        <div class="width-full">
                        <el-tabs class="login-tabs" @tab-click="loginTabChange" v-model="activeTab">
                            <el-tab-pane :label="$t('system.login_page_tab_phone')" name="phone"></el-tab-pane>
                            <el-tab-pane :label="$t('system.login_page_tab_account')" name="account"></el-tab-pane>
                        </el-tabs>
                        </div>
						<el-form style="width: 100%">
                            <el-form-item label="" style="margin-bottom: 15px;" v-show="activeTab === 'phone'">
								<el-input :placeholder="$t('system.login_page_form_phone_placeholder')" required clearable
                                    autocomplete="new"
                                    v-model="form.username" 
                                    @keydown.enter.native="(e) => submitLogin()">
                                    <el-select v-model="form.countryCode" filterable slot="prepend" placeholder="请选择" style="width: 85px;">
                                        <template v-for="(item, index) in countryCodeList">
                                            <el-option :label="`+${item.code}`" :value="item.code" :key="index">{{ `(+${item.code}) ${item.name}` }}</el-option>
                                        </template>
                                    </el-select>
                                </el-input>
							</el-form-item>
							<el-form-item label="" style="margin-bottom: 15px;" v-show="activeTab === 'account'">
								<el-input :placeholder="$t('system.login_page_form_account_placeholder')" required clearable
                                    autocomplete="new"
                                    v-model="form.username" 
                                    @keydown.enter.native="(e) => submitLogin()"
                                ></el-input>
							</el-form-item>
							<el-form-item label="" style="position: relative;margin-bottom: 15px;">
								<el-input :placeholder="$t('system.login_page_form_password_placeholder')" :type="showPassword?'text':'password'" required
									autocomplete="new-password"
                                    v-model="form.password"
									@keydown.enter.native="(e) => submitLogin()"
								></el-input>
								<div class="show-password" @click="setShowPassword">
									<svg-icon name="ib-open-eye" v-if="showPassword"></svg-icon>
                                    <svg-icon name="ib-close-eye" v-else></svg-icon>
								</div>
							</el-form-item>
						</el-form>
                        <div class="flex flex-justify-between fonts-14" style="width: 100%;margin-bottom:24px;">
                            <router-link to="/forget">{{$t('system.login_page_link_forgot')}}</router-link>
                            <router-link to="/signup">{{$t('system.login_page_link_signup')}}</router-link>
                        </div>
						<el-button type="primary" round @click="submitLogin" :loading="loading">{{$t('system.login_page_form_button')}}</el-button>
					</div>
                    <div class="bottom-placeholder flex flex-align-center">
                        <div class="flex flex-column flex-align-center cursor-pointer" @click="changeMode('wechat')">
                            <svg-icon name="ic-wechat" class-name="fonts-30"></svg-icon>
                            <div class="fonts-12 margin-t-10">{{$t('system.login_page_wechat')}}</div>
                        </div>
                    </div>
				</div>
                <div class="login-content" v-if="mode === 'wechat'">
                    <div class="top-placeholder"></div>
                    <template v-if="!needBindPhone">
                        <div class="fonts-20 text-weight-300 margin-b-16">{{$t('system.login_page_wechat_title')}}</div>
                        <div class="fonts-14 color-666666 margin-b-20">{{$t('system.login_page_wechat_subtitle')}}</div>
                        <div class="qrcode-content margin-b-18">
                            <img :src="wechat.qrcodeUrl" alt="qrcode" v-if="wechat"/>
                            <div class="code-expired" v-if="expired">
                                <svg-icon name="ib-reload-right" className="fonts-24"></svg-icon>
                                <a class="margin-t-4" href="javascript:;" @click="getWechatQrcode">{{$t('system.login_page_wechat_reload')}}</a>
                                <div class="margin-t-10 color-666666">{{$t('system.login_page_wechat_qrcode_overdue')}}</div>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="fonts-20 text-weight-300" style="margin-bottom: 34px">{{$t('system.login_page_bindphone_title')}}</div>
                        <div class="form-content">
                            <el-form style="width: 100%">
                                <el-form-item label="" style="margin-bottom: 15px;">
                                    <el-input :placeholder="$t('system.login_page_bindphone_form_phone_placeholder')" required clearable
                                        autocomplete="new"
                                        v-model="bindForm.phone" 
                                        @keydown.enter.native="(e) => submitLogin()">
                                        <el-select v-model="bindForm.countryCode" filterable slot="prepend" placeholder="请选择" style="width: 85px;">
                                            <template v-for="(item, index) in countryCodeList">
                                                <el-option :label="`+${item.code}`" :value="item.code" :key="index">{{ `(+${item.code}) ${item.name}` }}</el-option>
                                            </template>
                                        </el-select>
                                    </el-input>
                                </el-form-item>
                                <el-form-item label="" style="margin-bottom: 0;">
                                    <div class="flex">
                                        <div class="margin-r-10">
                                            <el-input :placeholder="$t('system.login_page_bindphone_form_captcha_placeholder')" required clearable 
                                                autocomplete="new"
                                                v-model="bindForm.captcha" 
                                                name="captcha"
                                                @keydown.enter.native="(e) => submitSignup()"
                                            ></el-input>
                                        </div>
                                        <div style="width: 120px;">
                                            <el-button round plain :disabled="hasSend" @click="getCaptcha" style="padding: 0;width:100%;height:40px;">{{hasSend?`${reSendTime}${$t('system.login_page_bindphone_form_send_captcha_seconds')}`:$t('system.login_page_bindphone_form_send_captcha')}}</el-button>
                                        </div>
                                    </div>
                                </el-form-item>
                            </el-form>
                            <el-button type="primary" round @click="submitBindPhone" :loading="loading" style="margin-top: 57px;">{{$t('system.login_page_form_button')}}</el-button>
                        </div>
                    </template>
                    <div class="bottom-placeholder flex flex-align-center fonts-15 color-link">
                        <template v-if="!needBindPhone">
                            <a href="javascript:;" @click="changeMode('password')">{{$t('system.login_page_link_password')}}</a>
                            <el-divider direction="vertical"></el-divider>
                            <router-link class="cursor-pointer" to="/signup">{{$t('system.login_page_link_signup')}}</router-link>
                        </template>
                    </div>
                </div>
            </template>
        </basic-page>
	</div>
</template>
<script>
import { Base64 } from "js-base64";
import { userApi } from "@/utils/api";
import config from "@/utils/config";
import tools from "@/utils/tools";
import BasicPage from './components/BasicPage';
import BasicPageLeft from './components/BasicPageLeft'
export default {
    components: { BasicPage, BasicPageLeft, },
	data() {
		return {
			config,
            activeTab: 'phone', // phone, account
            mode: 'password', //password, wechat, CAPTCHA
            needBindPhone: false,
            expired: false,
			form: {
				username: "",
				password: "",
                countryCode: "86",
			},
            bindForm:{
                countryCode: '86',
                phone: '',
                captcha: '',
            },
            openId: null,
			loading: false,
			showPassword: false,
			dialog: {
				show: false,
				type: 1, // 1=隐私政策，2=服务条款
			},
            wechat: null,
            hasSend:false,
            reSendTime:60,
            sendTimer:null,

            qrcodeTimer: null,

            countryCodeList: [],
		};
	},
	methods: {
		submitLogin() {
			let self = this;
			if (!this.form.username || !this.form.password) {
                if(this.activeTab === 'phone'){
                    this.$message.error(this.$t('system.login_page_form_verification_message_4'));
                }else {
                    this.$message.error(this.$t('system.login_page_form_verification_message_1'));
                }
				return;
			}
			let url = userApi.login;
			this.loading = true;
			this.$ajax.post(url, {
                account: this.form.username.trim(),
                password: Base64.encode(this.form.password),
                authType: this.activeTab.toLocaleUpperCase(),
                regionCode: this.activeTab === 'phone' ? '+' + this.form.countryCode : undefined,
            }).then((res) => {
                this.loading = false;
                if (res.status === 200) {
                    // let data = res.data;
                    this.getLoginSuccess();
                }
            }).catch((e) => {
                this.loading = false;
            });
		},
		setShowPassword() {
			this.showPassword = !this.showPassword;
		},
        async getLoginSuccess(){
            let self = this;
            await this.$store.dispatch("user/queryUserInfoAction", 'login');
            let redirect = localStorage.getItem("redirect");
            if (redirect && (redirect.indexOf('/forget')>-1 || redirect.indexOf('/signup') > -1)){
                redirect = '';
            }
            setTimeout(() => {
                if (redirect) {
                    localStorage.removeItem("redirect");
                    location.replace(redirect);
                    // self.$router.replace(redirect);
                } else {
                    location.replace("/");
                    // self.$router.replace('/');
                }
            }, 500);
        },
        changeMode(mode){
            this.mode = mode;
            if(mode === 'wechat'){
                this.$nextTick(() => {
                    this.getWechatQrcode();
                })
            }
        },
        getWechatQrcode(){
            let url = userApi.getWechatQrcode;
            this.$ajax.get(url).then(res => {
                if (res.status === 200){
                    this.expired = false;
                    this.wechat = {
                        // ...this.wechat,
                        ...res.data,
                    };
                    this.startPollingQrcodeStatus();
                }
            })
        },
        startPollingQrcodeStatus(){
            let self = this;
            self.pollingQrcodeStatus();
            this.qrcodeTimer = setInterval(() => {
                self.pollingQrcodeStatus();
            }, 1000);
        },
        pollingQrcodeStatus(){
            let url = `${userApi.pollingWechatLoginStatus}?isLogin=false&eventKey=${this.wechat.eventKey}`;
            this.$ajax.get(url).then(res => {
                if (res.status === 200){
                    let data = res.data;
                    let clear = false;
                    switch (data.status){
                        case 'expire': //二维码已失效
                            clear = true;
                            this.expired = true;
                            break;
                        case 'unbound': //未绑定手机号
                            clear = true;
                            this.needBindPhone = true;
                            this.openId = data.openId;
                            break;
                        case 'confirmed': //登录成功
                            clear = true;
                            this.getLoginSuccess();
                            break;
                        
                    }
                    if(clear){
                        clearInterval(this.qrcodeTimer);
                        this.qrcodeTimer = null;
                    }
                }
            });
        },
        getCaptcha(){
            let self = this;
            if(!this.bindForm.phone){
                this.$message.error(this.$t('system.login_page_form_verification_message_2'));
                return;
            }
            /* if(!tools.checkPhone(this.bindForm.phone)){
                this.$message.error(this.$t('system.login_page_form_verification_message_3'));
                return;
            } */
            this.$ajax.post(userApi.sendCaptcha, {account: this.bindForm.phone, regionCode: '+' + this.bindForm.countryCode}).then(res=> {
                if(res.status === 200){
                    this.$message.success(this.$t('system.login_page_form_sent_captcha_message'));
                    this.hasSend = true;
                    this.reSendTime = this.reSendTime - 1;
                    this.sendTimer=setInterval(()=> {
                        self.reSendTime = self.reSendTime - 1;
                        if(self.reSendTime < 0){
                            clearInterval(self.sendTimer);
                            self.reSendTime = 60;
                            self.hasSend = false;
                        }
                    }, 1000);
                }
            })
        },
        submitBindPhone(){
            if(!this.bindForm.phone){
                this.$message.error(this.$t('system.login_page_bindphone_form_verification_message_1'));
                return;
            }
            /* if(!tools.checkPhone(this.bindForm.phone)){
                this.$message.error(this.$t('system.login_page_bindphone_form_verification_message_2'));
                return;
            } */
            if(!this.bindForm.captcha){
                this.$message.error(this.$t('system.login_page_bindphone_form_verification_message_3'));
                return;
            }
            let url = userApi.bindWechatAndPhone;
            this.$ajax.post(url, { ...this.bindForm, regionCode: '+' + this.bindForm.countryCode, openId: this.openId, }).then(res => {
                if(res.status === 200){
                    this.getLoginSuccess();
                }
            })
        },
        loginTabChange({name}){
            this.$ls.set("loginTabName", name);
        },
	},
	created() {
        this.countryCodeList = this.$i18n.locale === 'zh-CN' ? this.$t('common.country_code_list') : this.$t('common.country_code_list').sort((a, b) => {
            if(a.name > b.name) {
                return 1;
            }else if(a.name < b.name) {
                return -1;
            }
            return 0;
        });
        let _name = this.$ls.get("loginTabName");
        if(_name){
            this.activeTab = _name;
        }
    },
    destroyed(){
        clearInterval(this.qrcodeTimer);
        this.qrcodeTimer = null;
    },
};
</script>
<style lang="scss" scoped>
.login-page {
	width: 100%;
	height: 100%;
	.login-content {
        .top-placeholder{
            height: 90px;
        }
        .bottom-placeholder{
            height: 122px;
            ::v-deep .el-divider{
                background-color: #999999;
            }
        }
		width: 460px;
		height: 460px;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
        .login-type {
            display: flex;
        }
	}
    .login-tabs{
        ::v-deep .el-tabs__nav-wrap::after {
            background-color: transparent;
        }
        ::v-deep .el-tabs__header {
            margin-bottom: 10px;
        }
        ::v-deep .el-tabs__item {
            padding: 0 10px;
            height: 30px;
            line-height: 30px;
        }
    }
}
</style>
